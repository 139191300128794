<template>
  <div class="text-editor">
    <div id="toolbar">
      <select class="ql-size">
        <option value="small">Small</option>
        <option value="">Normal</option>
        <option value="large">Large</option>
        <option value="huge">Huge</option>
      </select>
      <select class="ql-header">
        <option value="1">H1</option>
        <option value="2">H2</option>
        <option value="3">H3</option>
        <option value="4">H4</option>
        <option value="5">H5</option>
        <option value="6">H6</option>
        <option selected>Normal</option>
      </select>
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
      <select class="ql-color">
        <option value="#000000">#000000</option>
        <option value="#ff0000">#ff0000</option>
        <option value="#00ff00">#00ff00</option>
        <option value="#0000ff">#0000ff</option>
      </select>
      <button class="ql-link"></button>
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
      <button class="ql-align" value=""></button>
      <button class="ql-image"></button>
      <button class="ql-clean"></button>
    </div>

    <div id="editor" ref="quillEditor"></div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  name: "TextEditor",
  props: {
    modelValue: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  mounted() {
    if (this.$refs.quillEditor) {
      this.quill = new Quill(this.$refs.quillEditor, {
        theme: "snow",
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: () => {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/png, image/jpeg, image/jpg");
                input.click();

                input.onchange = () => {
                  const file = input.files[0];
                  const allowedExtensions = [".png", ".jpg", ".jpeg"];
                  const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();

                  if (allowedExtensions.includes(fileExtension)) {
                    this.addImages(file);
                  } else {
                    alert("Only .png, .jpg, or .jpeg files are allowed.");
                  }
                };
              }
            }
          }
        }
      });


      this.quill.on("text-change", () => {
        this.$emit("update:modelValue", this.quill.root.innerHTML);
      });

      this.quill.on("selection-change", (range) => {
        if (range === null || !this.quill.composing) {
          this.quill.update();
        }
      });
      this.quill.root.innerHTML = this.modelValue;
    }
  },
  watch: {
    modelValue(newValue) {
      if (this.quill && newValue !== this.quill.root.innerHTML) {
        const selection = this.quill.getSelection();
        this.quill.root.innerHTML = newValue;
        if (selection) {
          this.quill.setSelection(selection);
        }
      }
    }
  },
  methods: {
    addImages(file) {
      const formData = new FormData();
      formData.append("file", file);

      this.$appAxios.post("/admin/image/upload-image-cdn", formData).then((res) => {
        const imageUrl = res.data.imageUrl;
        this.insertToEditor(imageUrl);
      });
    },
    insertToEditor(url) {
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, "image", `${url}`);
    }
  }
};
</script>

<style>
.text-editor {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}
</style>
