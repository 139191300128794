<template>
  <Dialog
      :header="modalTitle"
      v-model:visible="showModal"
      modal
      :style="{ width: modalWidth }"
      @hide="hideModal"
  >
    <div v-for="(field, index) in formFields" :key="index" class="form-group">
      <label v-if="!(formData.isGeneral && (field.model === 'gender' || field.model === 'ascendantSignId'))" :for="field.label">{{ field.label }}</label>

      <!-- Text Input -->
      <InputText
          v-if="field.type === 'text'"
          :value="getNestedValue(formData, field.model)"
          @input="setNestedValue(formData, field.model, $event.target.value)"
          :placeholder="field.placeholder"
          :type="field.inputType || 'text'"
          class="w-100"
      />

      <div v-if="field.type === 'switch'" class="form-check form-switch">
        <!-- Switch input with getNestedValue and setNestedValue approach -->
        <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            :checked="getNestedValue(formData, field.model)"
            @change="setNestedValue(formData, field.model, $event.target.checked)"
        />
        <label class="form-check-label" for="flexSwitchCheckDefault">
           {{ getNestedValue(formData, field.model) }}
        </label>
      </div>


      <!-- Date Input -->
      <InputText
          v-if="field.type === 'date'"
          type="date"
          :value="formatDate(getNestedValue(formData, field.model))"
          @input="setNestedValue(formData, field.model, $event.target.value)"
          :placeholder="field.placeholder"
          class="w-100"
      />


      <!-- Number Input -->
      <InputText
          v-if="field.type === 'number'"
          type="number"
          :value="getNestedValue(formData, field.model)"
          @input="setNestedValue(formData, field.model, $event.target.value)"
          :placeholder="field.placeholder"
          class="w-100"
      />

      <div class="d-flex align-items-center gap-1" v-if="field.type === 'hour-minute' || field.type === 'minute' ">
        <InputText
            type="number"
            :value="getNestedValue(formData, field.hour)"
            @input="setNestedValue(formData, field.hour, $event.target.value)"
            :placeholder="field.placeholder"
            class="w-100"
        />
        :
        <InputText
            type="number"
            :value="getNestedValue(formData, field.minute)"
            @input="setNestedValue(formData, field.minute, $event.target.value)"
            :placeholder="field.placeholder"
            class="w-100"
        />
      </div>



      <!-- MultiSelect -->
      <MultiSelect
          v-if="field.type === 'multiselect'"
          v-model="formData[field.model]"
          :options="field.options"
          :optionLabel="field.optionLabel"
          :filter="field.filter"
          :placeholder="field.placeholder"
          class="w-100"
      />


      <Dropdown v-else-if="field.type === 'country'"
                v-model="formData[field.model]"
                :options="countries"
                optionLabel="name" optionValue="id"
                placeholder="Select a Country"
                filter
                @change="getCities"
                class="w-100"
      />

      <Dropdown v-else-if="field.type === 'city'"
                v-model="formData[field.model]"
                :options="cities"
                optionLabel="name" optionValue="id"
                placeholder="Select a City"
                @change="getDistricts"
                filter class="w-100"
      />

      <Dropdown v-else-if="field.type === 'state'"
                v-model="formData[field.model]"
                :options="districts"
                optionLabel="name" optionValue="id"
                placeholder="Select a District"
                filter class="w-100"/>

      <AutoComplete
          v-else-if="field.type === 'places'"
          v-model="selectedPlace"
          :suggestions="places"
          @complete="searchPlaces"
          field="display_name"
          placeholder="Search for a location"
          class="w-100"
      />


      <Dropdown v-else-if="field.type === 'birthCountryId'"
                v-model="birthCountryId"
                :options="countries"
                optionLabel="name" optionValue="id"
                placeholder="Select a Country"
                filter
                @change="getCities"
                class="w-100"
      />

      <Dropdown v-else-if="field.type === 'birthCityId'"
                v-model="birthCityId"
                :options="cities"
                optionLabel="name" optionValue="id"
                placeholder="Select a City"
                @change="getDistricts"
                filter class="w-100"
      />

      <Dropdown v-else-if="field.type === 'birthDistrictId'"
                v-model="birthDistrictId"
                :options="districts"
                optionLabel="name" optionValue="id"
                placeholder="Select a District"
                filter class="w-100"/>




      <!-- Select -->
      <select class="form-select"
              aria-label="Default select example"
              v-if="field.type === 'select' && !(formData.isGeneral && (field.model === 'gender' || field.model === 'ascendantSignId'))"
              @change="setNestedValue(formData, field.model, $event.target.value)"
              :value="getNestedValue(formData, field.model)">
        <option value="" disabled>Select an option</option>
        <option v-for="option in field.options"
                :key="option[field.value]"
                :value="option[field.value]">
          {{ option[field.text] }}
        </option>
      </select>




      <Dropdown v-else-if="field.type === 'drop'"
                v-model="formData[field.model]"
                :options="field.options"
                :optionLabel="field.value"
                :optionValue="field.id"
                class="w-100"
      />


      <Dropdown v-else-if="field.type === 'publicFigureLanguagesLanguage'"
                v-model="publicFigureLanguagesLanguage"
                :options="field.options"
                :optionLabel="field.value"
                :optionValue="field.id"
                class="w-100"
      />


      <MultiSelect v-else-if="field.type === 'multi'"
                   class="w-100"
                   :options="field.options"
                   v-model="formData[field.model]"
                   :optionLabel="field.text"
                   :optionValue="field.value"
                   multiple />

      <!-- Select ile Dil Bilgisi Seçme -->
      <div v-else-if="field.type === 'languageInfos'" class="form-group">
        <!-- Dil Seçimi ve Active Alanı -->
        <div class="d-flex gap-2 align-items-center">

          <MultiSelect
              class="w-100"
              :options="field.options"
              v-model="newLanguage.key"
              :optionLabel="field.value"
              :optionValue="field.id"
              multiple />

          <input v-model="newLanguage.isActive" type="checkbox" class="form-switch" /> Active
          <button type="button" class="btn btn-success" @click="addLanguage">+</button>
        </div>

        <!-- Seçilen Dil Bilgilerinin Listesi -->
        <div v-for="(langInfo, index) in languageInfos" :key="index" class="d-flex gap-2 mt-2  align-items-center">
          <span>{{ langInfo.language }}{{ langInfo.key }} - {{ langInfo.isActive ? 'Active' : 'Inactive' }}</span>
          <button type="button" class="btn btn-danger" @click="removeLanguage(index)">-</button>
        </div>
      </div>



      <div v-else-if="field.type === 'chakraContents'" class="form-group">

        <div class="d-flex gap-2 flex-column">
          <label :for="field.model">Contents Type</label>
          <input
              :id="field.model"
              :placeholder="'Enter Contents Type'"
              v-model="newContent.type"
              type="text"
              class="form-control w-100" />

          <label :for="field.model">Contents Variant</label>
          <input
              :id="field.model"
              :placeholder="'Enter Contents Variant'"
              v-model="newContent.variant"
              type="number"
              class="form-control w-100" />

          <label :for="field.model">Contents Text</label>
          <input
              :id="field.model"
              :placeholder="'Enter Contents Text'"
              v-model="newContent.text"
              type="text"
              class="form-control w-100" />
          <label :for="field.model">Contents Language</label>
          <Dropdown
              class="w-100"
              :options="languages"
              v-model="newContent.language"
              optionLabel="languageKey"
              optionValue="languageKey"
              :placeholder="'Enter Contents Language'"
          />
          <label :for="field.model">Contents Image Path</label>
          <input
              :id="field.model"
              :placeholder="'Enter Contents Image Path'"
              v-model="newContent.imagePath"
              type="text"
              class="form-control w-100" />

          <button type="button" class="btn btn-success" @click="addChakraContent">+</button>
        </div>

        <div style="overflow: auto;max-height: 200px;">
          <div v-for="(langInfo, index) in chakraContents" :key="index" class="d-flex gap-2 mt-2 align-items-center">
            <span>{{ langInfo }}</span>
            <button type="button" class="btn btn-danger" @click="removeChakraContent(index)">-</button>
          </div>
        </div>

      </div>


      <div v-else-if="field.type === 'properties'" class="form-group">
        <div class="d-flex gap-2 align-items-center">
          <input
              :id="field.model"
              :placeholder="field.placeholder || 'Metin girin'"
              v-model="inputValue"
              type="text"
              class="form-control w-100" />
          <button type="button" class="btn btn-success" @click="addToArray">+</button>
        </div>

        <div v-for="(item, index) in arrayData" :key="index" class="d-flex gap-2 mt-2 align-items-center">
          <input
              type="text"
              class="form-control w-100"
              v-model="arrayData[index]"
              placeholder="Yeni değer girin"
          />
          <button type="button" class="btn btn-danger" @click="removeFromArray(index)">Sil</button>
        </div>
      </div>



    </div>

    <!-- Mesajlar -->
    <Message
        v-for="msg in messages"
        :severity="msg.severity"
        :key="msg.id"
        :life="5000"
        :sticky="false"
    >
      {{ msg.content }}
    </Message>

    <!-- Footer -->
    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-default"
          @click="hideModal"
      >
        Close
      </button>
      <Button
          label="Save"
          icon="pi pi-save"
          iconPos="left"
          @click="saveChanges"
      />
    </div>
  </Dialog>
</template>

<script>
export default {
  props: ['show', 'modalTitle', 'modalWidth', 'formFields','detailEndpoint','detailData','totalDataName','saveEndpoint','method','isOnlydata','isOnlyNodata'],
  data() {
    return {
      formData: {},
      messages: [],
      countries: [],
      cities: [],
      districts: [],
      languages: [],
      birthCountryId: null,
      birthCityId: null,
      birthDistrictId: null,
      publicFigureLanguagesLanguage: null,
      newLanguage: {
        key: '',
        isActive: false
      },
      languageInfos: [],
      newContent: {
        id: null,
        contentTranslationId: null,
        type: '',
        variant: '',
        text: '',
        language: '',
        imagePath: ''
      },
      chakraContents: [],
      inputValue: '',
      arrayData: [],
      selectedPlace: null,
      places: []
    };
  },
  watch: {
    detailData(newValue) {
      if (newValue){
        this.fetchDetail();
      }
    },
    showModal(newVal) {
      if (newVal) {
        this.fetchDetail();
      }
    },
    publicFigureLanguages(newVal) {
      this.formData.publicFigureLanguages = newVal;
    },
    selectedPlace(newVal) {
      this.formData.userInfo.birthPlace = newVal;
    },
    birthCityId(newVal) {
      this.formData.userInfo.birthCityId = newVal;
      this.getDistricts(newVal);
    },
    birthDistrictId(newVal) {
      this.formData.userInfo.birthDistrictId = newVal;
    }
  },
  mounted() {
    this.$appAxios.get("global/countries")
        .then(resp => {
          this.countries = resp.data.response
        })
        .catch(err => {
          console.log(err)
        });
    this.$appAxios.get("admin/app-settings/language/list?test=test&offset=0&limit=10000")
        .then(resp => {
          this.languages = resp.data.languages
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      },
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    fetchDetail() {
      if (this.detailEndpoint){
        this.$appAxios.get(this.detailEndpoint)
            .then((response) => {
              if (this.isOnlydata){
                this.formData = response.data[this.totalDataName];
                if (this.formData?.publicFigureLanguages){
                  this.languageInfos = this.formData.publicFigureLanguages
                }
                if (this.formData?.properties){
                  this.arrayData = this.formData.properties.map(item => item.ragReportTitle);
                }



                this.selectedPlace = this.formData.userInfo.birthPlace

                this.formData.placeOfBirth = {
                  "longitude": this.formData.userInfo.birthLongitude,
                  "latitude": this.formData.userInfo.birthLatitude
                }

              }else if (this.isOnlyNodata){
                this.formData = response.data;
                if (this.formData?.userInfo?.birthPlace){
                  this.selectedPlace = this.formData.userInfo.birthPlace

                  this.formData.placeOfBirth = {
                    "longitude": this.formData.userInfo.birthLongitude,
                    "latitude": this.formData.userInfo.birthLatitude
                  }
                }
                if (this.formData?.properties){

                  this.arrayData = this.formData.properties.map(item => item.segmentKey);
                }

                if (this.formData?.options){
                  this.arrayData = this.formData.options.map(item => item);
                }

              }else {
                this.formData = response.data.data[this.totalDataName];
                if (this.formData.chakraContents){
                  this.chakraContents = this.formData?.chakraContents;
                }
                if (this.formData.contentTranslations){
                  const contentTranslations = this.formData?.contentTranslations;
                  const chakraContentsMap = Object.fromEntries(this.chakraContents.map(item => [item.id, item]));

                  this.chakraContents = contentTranslations.map(translation => {
                    const chakraContent = chakraContentsMap[translation.chakraContentId];
                    return {
                      id: translation.chakraContentId,
                      contentTranslationId: translation.contentTranslationId,
                      text: translation.text,
                      language: translation.language,
                      imagePath: translation.imagePath,
                      type: chakraContent?.type || null,
                      variant: chakraContent?.variant || null
                    };
                  });
                }
              }
            })
            .catch((err) => {
              console.error('Error fetching details:', err);
            });
      } else if (this.detailData){
        this.formData = this.detailData
      }
    },
    saveChanges() {
      this.formData.chakraContents = this.chakraContents
      this.formData.properties = this.arrayData
      this.formData.options = this.arrayData
      this.formData.placeOfBirth = this.selectedPlace

      this.$emit('saveEndpoint', this.formData)
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },

    setNestedValue(obj, path, value) {
      const keys = path.split('.');
      keys.reduce((acc, part, index) => {
        if (index === keys.length - 1) {
          acc[part] = value;
        }
        return acc[part];
      }, obj);
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // YYYY-MM-DD formatı
    },
    searchPlaces(event) {
      const query = event.query;
      this.$appAxios
          .get(`/global/v3/location/${query}`)
          .then(response => {
            this.places = response.data.response.placeList.result.map(place => ({
              id: place.place_id,
              display_name: place.display_name,
              lat: place.lat,
              lon: place.lon,
              type: place.type
            }));
          })
          .catch(err => {
            console.error("Error fetching places:", err);
          });
    },

    getCities(event) {
      this.$appAxios.get("global/cities/" + event)
          .then(resp => {
            this.cities = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    getDistricts(event) {
      this.$appAxios.get("global/districts/" + event)
          .then(resp => {
            this.districts = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    addLanguage() {
      if (this.newLanguage.key.length > 0) {
        this.newLanguage.key.forEach((languageKey) => {
          const existingLanguage = this.languageInfos.find(lang => lang.key === languageKey);

          if (!existingLanguage) {
            this.languageInfos.push({
              key: languageKey,
              isActive: this.newLanguage.isActive
            });
          }
        });

        this.newLanguage.key = [];
        this.newLanguage.isActive = false;
      }
    },
    removeLanguage(index) {
      this.languageInfos.splice(index, 1);
    },
    addToArray() {
      if (this.inputValue.trim()) {
        this.arrayData.push(this.inputValue.trim());
        this.inputValue = '';
      }
    },
    removeFromArray(index) {
      this.arrayData.splice(index, 1);
    },
    addChakraContent() {
      this.chakraContents.push({ ...this.newContent });
      this.newContent = { id: null, contentTranslationId: null,type: '', variant: '', text: '', language: '', imagePath: '' };
    },
    removeChakraContent(index) {
      this.chakraContents.splice(index, 1);
    },
  },
};
</script>
