<template>
  <div
      class="list-group menu"
      role="tablist"
      v-if="this.$store.state.token"
  >
    <router-link to="/" role="tab" class="list-group-item list-group-item-action  ">{{ $t("menu.home") }}</router-link>

    <div class="w-100">

      <!-- Mobil Menü Butonu -->
      <button
          class="btn list-group-item list-group-item-action "
          style="color: black !important; font-weight: bold;"
          @click="toggleMenu"
      >
        {{ $t("menu.contents") }}
      </button>

      <!-- Menü - Açılabilir Mobil Menü -->
      <ul v-show="isMenuOpen" class="menu-mobile px-4 bg-white" style="list-style-type: none;">
        <li class="py-1" style="text-align: left;">
          <router-link to="/weeklyContent" role="tab">{{ $t("menu.weekly-contents") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/monthlyContent" role="tab">{{ $t("menu.monthly-contents") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/yearly" role="tab">{{ $t("menu.yearly") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/cosmic-events" role="tab">{{ $t("menu.Cosmic") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/stories" role="tab">{{ $t("menu.Stories") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/calendar" role="tab">{{ $t("menu.Calendar") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/images" role="tab">{{ $t("menu.Images") }}</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/chakra-list" role="tab">Chakra</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/what-we-do" role="tab">What we do</router-link>
        </li>
        <li class="py-1" style="text-align: left;">
          <router-link to="/rag-list" role="tab">{{$t('menu.Ragreport')}}</router-link>
        </li>
      </ul>

      <a class="menu-item px-4 list-group-item list-group-item-action  " @click="toggleContentPool" style="color: black !important; font-weight: bold;">
        Content Pool
      </a>
      <ul v-show="isContentPoolOpen" class="px-4 bg-white" style="list-style-type: none;">
        <li class="py-1">
          <router-link to="/dailyContent" class="menu-secondary-item" role="tab">{{$t('menu.daily-contents')}}</router-link>
        </li>
        <li class="py-1">
          <router-link to="/do-dont" class="menu-secondary-item" role="tab">{{$t('menu.do-dont')}}</router-link>
        </li>
        <li class="py-1">
          <router-link to="/biorhythm" class="menu-secondary-item" role="tab">{{$t('menu.Biorhythm')}}</router-link>
        </li>
        <li class="py-1">
          <router-link to="/celestial-cards" class="menu-secondary-item" role="tab">{{$t('menu.Celestial')}}</router-link>
        </li>
      </ul>


      <a class="menu-item px-4 list-group-item list-group-item-action  " @click="toggleAdmin" style="color: black !important; font-weight: bold;">
        Admin
      </a>
      <ul v-show="isAdminOpen" class="px-4 bg-white" style="list-style-type: none;">
        <li class="py-1"><router-link to="/survey-list" class="menu-secondary-item px-4" role="tab">{{$t('menu.Surveys')}}</router-link></li>
        <li class="py-1"><router-link to="/segments-list" class="menu-secondary-item px-4" role="tab">Segments</router-link></li>
        <li class="py-1"><router-link to="/splash" class="menu-secondary-item px-4" role="tab">Splash</router-link></li>
        <li class="py-1"><router-link to="/question-templates" class="menu-secondary-item px-4" role="tab">Question templates</router-link></li>
        <li class="py-1"><router-link to="/upload-story" class="menu-secondary-item px-4" role="tab">Upload story</router-link></li>
        <li class="py-1"><router-link to="/give-extra-credit" class="menu-secondary-item" role="tab">Give extra credit</router-link></li>
        <li class="py-1"><router-link to="/in-app-message" class="menu-secondary-item" role="tab">In app message</router-link></li>
        <li class="py-1"><router-link to="/verify-content" class="menu-secondary-item" role="tab">{{$t('menu.VerifyContent')}}</router-link></li>
        <li class="py-1"><router-link to="/upload-country-city" class="menu-secondary-item" role="tab">Upload country city</router-link></li>
        <li class="py-1"><router-link to="/app-language-settings" class="menu-secondary-item" role="tab">{{$t('menu.LanguageSettings')}}</router-link></li>
        <li class="py-1"><router-link to="/testAstrologyAPI" class="menu-secondary-item" role="tab">Astrology API Test</router-link></li>
      </ul>


      <a class="menu-item px-4 list-group-item list-group-item-action  " @click="toggleNatal" style="color: black !important; font-weight: bold;">
        {{ $t("menu.natal-contents") }}
      </a>
      <ul v-show="isNatalOpen" class="px-4 bg-white" style="list-style-type: none;">
        <li class="py-1"><router-link to="/natalContent" class="menu-secondary-item" role="tab">{{$t('menu.natal-map')}}</router-link></li>
        <li class="py-1"><router-link to="/aspectContent" class="menu-secondary-item" role="tab">{{$t('menu.natal-aspect')}}</router-link></li>
      </ul>


      <router-link to="/users" class="menu-item px-4 list-group-item list-group-item-action  " role="tab" @click="toggleUsers" style="color: black !important; font-weight: bold;">{{$t('menu.users')}}</router-link>
      <ul v-show="isUsersOpen" class="px-4 bg-white" style="list-style-type: none;">
        <li class="py-1"><router-link to="/users" class="menu-secondary-item" role="tab">{{$t('menu.users')}}</router-link></li>
        <li class="py-1"><router-link to="/users/public-figures" class="menu-secondary-item" role="tab">{{$t('menu.PublicFigures')}}</router-link></li>
      </ul>

      <a class="menu-item px-4 list-group-item list-group-item-action  " style="color: black !important; font-weight: bold;" @click="toggleDiscover">
        {{ $t("menu.discover") }}
      </a>
      <ul v-show="isDiscoverOpen" class="px-4 bg-white" style="list-style-type: none;">
        <li class="py-1"><router-link to="/discover/weekly" class="menu-secondary-item" role="tab">{{$t('menu.weekly-discover')}}</router-link></li>
        <li class="py-1"><router-link to="/discover/affirmations" class="menu-secondary-item" role="tab">{{$t('menu.affirmations')}}</router-link></li>
        <li class="py-1"><router-link to="/discover/cards" class="menu-secondary-item" role="tab">{{$t('menu.cards')}}</router-link></li>
        <li class="py-1"><router-link to="/discover/suggestions" class="menu-secondary-item" role="tab">{{$t('menu.suggestions')}}</router-link></li>
      </ul>
      <router-link to="/contentLookUp" class="menu-item px-4 list-group-item list-group-item-action  "  style="color: black !important; font-weight: bold;">{{ $t("menu.content-look-up") }}</router-link>
      <a class="menu-item px-4 list-group-item list-group-item-action  " @click="toggleAstrologs" style="color: black !important; font-weight: bold;">
        {{$t('menu.Astrologs')}}
      </a>
      <ul v-show="isAstrologsOpen" class="px-4 bg-white" style="list-style-type: none;">
        <li class="py-1"><router-link to="/questions" class="menu-secondary-item" role="tab">{{ $t("menu.Questions") }}</router-link></li>
        <li class="py-1"><router-link to="/astrologs" class="menu-secondary-item" role="tab">{{ $t("menu.Astrologs") }}</router-link></li>
      </ul>

    </div>
    <!-- Logout -->
    <a
        class="list-group-item list-group-item-action pb-4 logout"
        role="tab"
        @click="logout"
    >
      {{ $t("menu.exit") }}
    </a>
  </div>
</template>

<script>
import LocalSwitcher from "@/components/LocalSwitcher.vue";

export default {
  name: "Menu",
  components: {
    LocalSwitcher,
  },
  data() {
    return {
      isMenuOpen: false,
      isContentPoolOpen: false,
      isAdminOpen: false,
      isNatalOpen: false,
      isUsersOpen: false,
      isDiscoverOpen: false,
      isAstrologsOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleAdmin() {
      this.isAdminOpen = !this.isAdminOpen;
    },
    toggleContentPool() {
      this.isContentPoolOpen = !this.isContentPoolOpen;
    },
    toggleNatal() {
      this.isNatalOpen = !this.isNatalOpen;
    },
    toggleUsers() {
      this.isUsersOpen = !this.isUsersOpen;
    },
    toggleDiscover() {
      this.isDiscoverOpen = !this.isDiscoverOpen;
    },
    toggleAstrologs() {
      this.isAstrologsOpen = !this.isAstrologsOpen;
    },
    logout() {
      this.$swal
          .fire({
            text: this.$t("menu.exitConfirm"),
            showCancelButton: true,
            customClass: {
              container: "my-sweet",
            },
            confirmButtonText: this.$t("global.yes"),
            cancelButtonText: this.$t("global.no"),
            icon: "warning",
          })
          .then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("userInfo");
              localStorage.removeItem("token");
              window.location = "/";
            }
          });
    },
  },
};
</script>

<style scoped>
.menu {
  transition: 3s;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  left: -100%;
  top: 0;
  z-index: 4;
  flex-direction: column;
  background-color: #ffffff;
  text-align: center;
  transition: 0.6s;
  overflow-y: scroll;
  overflow-x: hidden;
}

.menu-first {
  padding-top: 30px;
}

.list-group {
  width: 40% !important;
}

.icon-box {
  height: 60px;
  width: 60px;
  background: #eff0f1;
  border-radius: 6px;
}

.menu .list-group-item {
  font-size: 15px;
  font-family: "Ubuntu";
  line-height: 24px;
  color: #6367b7;
  background-color: #ffffff;
  border-color: transparent;
  display: flex;
  align-items: center;
  line-height: 35px;
  cursor: pointer;
  text-align: start;
}

.menu-toggle {
  background: #fd6a57;
  color: white;
  border: none;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .menu {
    width: 30% !important;
  }
}

@media (min-width: 672px) {
  .menu {
    width: 20% !important;
    transition: 3s;
  }
}

a {
  color: #475569 !important;
}

.menu-mobile {
  display: block;
  background-color: #f9f9f9;
}
</style>
