<template>
  <header
      :class="`header py-md-4 px-sm-2 px-md-4 py-2 d-flex align-items-center container`"
  >
    <div
        class="
        d-flex
        align-items-center
        justify-content-center justify-content-lg-between
        w-100
        p-2 p-md-0
      "
    >
      <!-- hamburger menu -->
      <div
          class="hamburger d-flex d-lg-none"
          @click="toggle"
          v-bind:class="{ active: this.$store.state.toggeled }"
          v-if="this.$store.state.token"
      >
        <svg
            v-if="!this.$store.state.toggeled"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 10H22V8H2V10Z" fill="#556470" />
          <path d="M2 15H22V13H2V15Z" fill="#556470" />
          <path d="M2 20H22V18H2V20Z" fill="#556470" />
        </svg>
        <svg
            v-if="this.$store.state.toggeled"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M24.6256 9.2541C25.0161 8.86357 25.0161 8.23041 24.6256 7.83989L24.1598 7.3741C23.7693 6.98357 23.1361 6.98357 22.7456 7.3741L15.9993 14.1203L9.25312 7.3741C8.8626 6.98357 8.22943 6.98357 7.83891 7.3741L7.37312 7.83989C6.9826 8.23041 6.9826 8.86357 7.37312 9.2541L14.1193 16.0003L7.37312 22.7466C6.9826 23.1371 6.9826 23.7702 7.37312 24.1608L7.83891 24.6266C8.22943 25.0171 8.8626 25.0171 9.25312 24.6266L15.9993 17.8803L22.7456 24.6266C23.1361 25.0171 23.7693 25.0171 24.1598 24.6266L24.6256 24.1608C25.0161 23.7702 25.0161 23.1371 24.6256 22.7466L17.8793 16.0003L24.6256 9.2541Z"
              fill="#71B0BD"
          />
        </svg>
      </div>
      <!-- logo -->

      <div class="d-flex align-items-center justify-content-center gap-3">
        <a
            class="nav-logo d-flex align-items-center"
            v-bind:class="[this.$store.state.token ? 'justify-content-end' : '']"
            @click="$router.push('/')"
        >
          <LogoSVG/>
        </a>
        <a class="p-button p-component p-button-info d-inline me-2 p-1 text-white" href="https://admin.astopia.com/welcome" target="_blank">Admin Panel</a>
      </div>


      <ul class="menu d-none d-lg-flex align-items-stretch" v-if="this.$store.state.token">
        <li>
          <a class="menu-item px-4">
            {{ $t("menu.contents") }}
          </a>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/weeklyContent" class="menu-secondary-item" role="tab">{{ $t("menu.weekly-contents") }}</router-link></li>
            <li class="py-1"><router-link to="/monthlyContent" class="menu-secondary-item" role="tab">{{ $t("menu.monthly-contents") }}</router-link></li>
            <li class="py-1"><router-link to="/yearly" class="menu-secondary-item" role="tab">{{ $t("menu.yearly") }}</router-link></li>
            <li class="py-1"><router-link to="/cosmic-events" class="menu-secondary-item" role="tab">{{ $t("menu.Cosmic") }}</router-link></li>
            <li class="py-1"><router-link to="/stories" class="menu-secondary-item" role="tab">{{ $t("menu.Stories") }}</router-link></li>
            <li class="py-1"><router-link to="/calendar" class="menu-secondary-item" role="tab">{{ $t("menu.Calendar") }}</router-link></li>
            <li class="py-1"><router-link to="/images" class="menu-secondary-item" role="tab">{{ $t("menu.Images") }}</router-link></li>
            <li class="py-1"><router-link to="/chakra-list" class="menu-secondary-item" role="tab">Chakra</router-link></li>
            <li class="py-1"><router-link to="/what-we-do" class="menu-secondary-item" role="tab">What we do</router-link></li>
            <li class="py-1"><router-link to="/rag-list" class="menu-secondary-item" role="tab">{{$t('menu.Ragreport')}}</router-link></li>
          </ul>
        </li>
        <li>
          <a class="menu-item px-4">
            Content Pool
          </a>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/dailyContent" class="menu-secondary-item" role="tab">{{$t('menu.daily-contents')}}</router-link></li>
            <li class="py-1"><router-link to="/do-dont" class="menu-secondary-item" role="tab">{{$t('menu.do-dont')}}</router-link></li>
            <li class="py-1"><router-link to="/biorhythm" class="menu-secondary-item" role="tab">{{$t('menu.Biorhythm')}}</router-link></li>
            <li class="py-1"><router-link to="/celestial-cards" class="menu-secondary-item" role="tab">{{$t('menu.Celestial')}}</router-link></li>
          </ul>
        </li>

        <li>
          <a class="menu-item px-4">
            Admin
          </a>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/survey-list" class="menu-secondary-item px-4" role="tab">{{$t('menu.Surveys')}}</router-link></li>
            <li class="py-1"><router-link to="/segments-list" class="menu-secondary-item px-4" role="tab">Segments</router-link></li>
            <li class="py-1"><router-link to="/splash" class="menu-secondary-item px-4" role="tab">Splash</router-link></li>
            <li class="py-1"><router-link to="/question-templates" class="menu-secondary-item px-4" role="tab">Question templates</router-link></li>
            <li class="py-1"><router-link to="/upload-story" class="menu-secondary-item px-4" role="tab">Upload story</router-link></li>
            <li class="py-1"><router-link to="/give-extra-credit" class="menu-secondary-item px-4" role="tab">Give extra credit</router-link></li>
            <li class="py-1"><router-link to="/in-app-message" class="menu-secondary-item px-4" role="tab">In app message</router-link></li>
            <li class="py-1"><router-link to="/verify-content" class="menu-secondary-item px-4" role="tab">{{$t('menu.VerifyContent')}}</router-link></li>
            <li class="py-1"><router-link to="/upload-country-city" class="menu-secondary-item px-4" role="tab">Upload country city</router-link></li>
            <li class="py-1"><router-link to="/app-language-settings" class="menu-secondary-item px-4" role="tab">{{$t('menu.LanguageSettings')}}</router-link></li>
            <li class="py-1"><router-link to="/testAstrologyAPI" class="menu-secondary-item px-4" role="tab">Astrology API Test</router-link></li>
          </ul>
        </li>
        <li>
          <a class="menu-item px-4">
            {{ $t("menu.natal-contents") }}
          </a>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/natalContent" class="menu-secondary-item" role="tab">{{$t('menu.natal-map')}}</router-link></li>
            <li class="py-1"><router-link to="/aspectContent" class="menu-secondary-item" role="tab">{{$t('menu.natal-aspect')}}</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/users" class="menu-item px-4" role="tab">{{$t('menu.users')}}</router-link>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/users" class="menu-secondary-item" role="tab">{{$t('menu.users')}}</router-link></li>
            <li class="py-1"><router-link to="/users/public-figures" class="menu-secondary-item" role="tab">{{$t('menu.PublicFigures')}}</router-link></li>
          </ul>
        </li>
        <li>
          <a class="menu-item px-4">
            {{ $t("menu.discover") }}
          </a>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/discover/weekly" class="menu-secondary-item" role="tab">{{$t('menu.weekly-discover')}}</router-link></li>
            <li class="py-1"><router-link to="/discover/affirmations" class="menu-secondary-item" role="tab">{{$t('menu.affirmations')}}</router-link></li>
            <li class="py-1"><router-link to="/discover/cards" class="menu-secondary-item" role="tab">{{$t('menu.cards')}}</router-link></li>
            <li class="py-1"><router-link to="/discover/suggestions" class="menu-secondary-item" role="tab">{{$t('menu.suggestions')}}</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/contentLookUp" class="menu-item px-4" >{{ $t("menu.content-look-up") }}</router-link>
        </li>
        <li>
          <a class="menu-item px-4">
            {{$t('menu.Astrologs')}}
          </a>
          <ul class="px-4 bg-white">
            <li class="py-1"><router-link to="/questions" class="menu-secondary-item" role="tab">{{ $t("menu.Questions") }}</router-link></li>
            <li class="py-1"><router-link to="/astrologs" class="menu-secondary-item" role="tab">{{ $t("menu.Astrologs") }}</router-link></li>
          </ul>
        </li>

      </ul>



      <ul class="m-0 p-0 d-flex align-items-center">
        <li>
          <a
              @click.prevent="onLogout">
            Log out
          </a>
        </li>
        <li class="nav-item m-0 d-flex align-items-center">
          <a
              v-if="this.$store.state.token && isUserValid"
              class="ms-3 d-none d-md-block"
          >
            <LocalSwitcher />
          </a>
          <a v-else class="ms-3">
            <LocalSwitcher />
          </a>
        </li>
      </ul>
    </div>
  </header>
  <nav class="navbar d-flex d-lg-none navbar-slider" v-if="this.$store.state.token">
    <div class="d-flex justify-content-between">
      <MobileMenu
          v-bind:class="{ active: this.$store.state.toggeled }"
          class="w-25 nav-menu p-0 d-flex"
      />
      <div
          v-show="this.$store.state.toggeled"
          @click="toggle"
          class="header-menu-wrapper-overlay"
          v-bind:class="{ active: this.$store.state.toggeled }"
      ></div>
    </div>
  </nav>
</template>

<script>
import MobileMenu from "@/components/MobileMenu.vue";
import LogoSVG from "@/components/LogoSVG.vue";
import LocalSwitcher from "@/components/LocalSwitcher.vue";

export default {
  props: {
    headerClass: {
      type: String,
      default: "",
    },
    logoWhite: {
      type: Boolean,
      default: false,
    },
  },
  name: "Header",
  components: {
    MobileMenu,
    LocalSwitcher,
    LogoSVG
  },
  data() {
    return {};
  },
  mounted() {
    var scrollTrigger = 60;

    window.onscroll = function () {
      // We add pageYOffset for compatibility with IE.
      if (
          window.scrollY >= scrollTrigger ||
          window.pageYOffset >= scrollTrigger
      ) {
        document.getElementsByTagName("header")[0].classList.add("scroll");
      } else {
        document.getElementsByTagName("header")[0].classList.remove("scroll");
      }
    };
  },
  computed: {
    isUserValid() {
      if (
          this.isEmpty(this.$store.state.userInfo) ||
          this.isEmpty(this.$store.state.userInfo.name)
      ) {
        return false;
      }

      return true;
    },
    getUserName() {
      try {
        if (this.$store.state.userInfo !== undefined) {
          return (
              this.$store.state.userInfo.name +
              " " +
              this.$store.state.userInfo.surname.substr(0, 1) +
              "."
          );
        } else return "";
      } catch {
        return "";
      }
    },
    getLetters() {
      try {
        if (this.$store.state.userInfo !== undefined) {
          return (
              this.$store.state.userInfo.name.substr(0, 1) +
              this.$store.state.userInfo.surname.substr(0, 1)
          );
        } else return "";
      } catch {
        return "";
      }
    },
  },
  methods: {
    toggle() {
      this.$store.commit("setToggled");
    },
    onLogout() {
      localStorage.removeItem("token"); // Token'ı localStorage'dan temizle
      this.$router.push("/welcome");
    }
  },
};
</script>

<style scoped>
.nav-logo {
  cursor: pointer;
}
.avatar-circle {
  width: 40px;
  height: 40px;
  background-color: #6367b7;
  text-align: center;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  cursor: pointer;
}

.initials {
  display: flex;
  font-size: 17px;
  color: #fff;
  font-weight: 700;
  justify-content: center;
  height: 100%;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.transparent {
  background: transparent !important;
}

.scroll {
  background: #ffffff !important;
}

.welcoming {
  font-family: Open Sans;
  color: #504566;
}

.welcoming p {
  font-size: 13px;
  font-weight: 400;
}

.welcoming span {
  font-size: 14px;
  font-weight: 700;
}

.cart-icon {
  margin-left: 5px;
}

.header {
  z-index: 4;
  background-color: #ffff;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}

.header > div {
  margin: 0 auto;
  /* max-width: 1320px; */
}

.header a {
  text-decoration: none;
  cursor: pointer;
}

.header a:hover {
  text-decoration: none;
}

.header li {
  list-style: none;
}

.header li a {
  font-size: 14px;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 1.5rem;
}

.hamburger {
  display: none;
}

.navbar-slider {
  z-index: 6;
}

.navbar-slider ul {
  list-style: none;
}

.nav-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-menu.active {
  background-color: #ffffff;
  z-index: 3;
}

.nav-item {
  margin-left: 5rem;
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 500;
  color: #475569;
}

.nav-link:hover {
  color: #475569;
}

.hamburger-container {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: #eff0f1;
  border-radius: 6px;
}

@media only screen and (max-width: 992px) {
  .navbar {
    padding: 0 !important;
    margin: 0 !important;
    margin: 0 auto;
    display: flex;
    width: 40% !important;
  }

  .logo {
    width: 70%;
  }

  .nav-menu {
    display: flex;
    align-items: center;
    position: fixed;
    height: 100vh;
    left: -100%;
    top: 0;
    z-index: 4;
    flex-direction: column;
    width: 30%;
    text-align: center;
    transition: 0.6s;
    /* box-shadow: 0 10px 27px rgba(0, 0, 0, 0.3); */
    margin-top: 65px !important;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 0.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .header-menu-wrapper-overlay {
    background-color: #3e39477a;
    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    -webkit-animation: animation-offcanvas-fade-in 0.6s ease 1;
    animation: animation-offcanvas-fade-in 0.6s ease 1;
  }
}

@media only screen and (max-width: 576px) {
  .nav-menu {
    width: 45%;
  }
}

@media only screen and (max-width: 991.98px) {
  .navbar {
    width: 30% !important;
    padding: 0;
  }

  .header {
    max-width: 100%;
  }

  .header > div {
    justify-content: space-between !important;
  }
}

.exit {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.menu-item{
  color: #111 !important;
  font-weight: 500;
  font-size: 16px !important;
}

.menu-secondary-item{
  color: #555 !important;
  font-weight: 500;
  font-size: 14px !important;
}

/* Hide Dropdowns by Default */
.menu ul {
  display: none;
  position: absolute;
  top: 60px; /* the height of the main nav */
}

/* Display Dropdowns on Hover */
.menu li:hover > ul {
  display:inherit;
}

.menu ul li {
  float:none;
  display:list-item;
  position: relative;
}

ul {
  list-style-type: none;
}
</style>